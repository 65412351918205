/*! DFC AB Testing Framework: 2.0.0.2025-04-02T20:27:26.587Z */
"use strict";

// framework v2 helper functions / bootstrapping
if (!function lsTest() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}()) {
  throw new Error('DFC: localStorage required.');
}
(() => {
  const dfc = window.dfc; // for minification

  const doNotTrack = () => {
    localStorage.setItem('dfc-do-not-track', 'true');
    dfc.DO_NOT_TRACK = true;
  };
  dfc.DO_NOT_TRACK = localStorage.getItem('dfc-do-not-track') === 'true';
  if (/\b(bot|crawler|spider|slurp)\b/i.test(navigator.userAgent)) {
    doNotTrack();
  }
  const searchParams = new URLSearchParams(location.search);

  // first we reset params if needed
  if (searchParams.get('dfc-reset') || searchParams.get('dfc-reset-all')) {
    for (const key in localStorage) {
      if (/^dfc[-:]/.test(key)) {
        localStorage.removeItem(key);
      }
    }
  }

  // then we recoup the params set from preview link
  const prefix = 'dfc-exp-';
  try {
    const force = new RegExp(`^${prefix}`);
    searchParams.forEach((value, key) => {
      if (force.test(key)) {
        localStorage.setItem(key, value);
        doNotTrack();
      }
    });
  } catch (e) {
    // do nothing b/c old browsers don't support URLSearchParams
    // eslint-disable-next-line no-console
    console.error(e);
  }

  // Reset disable if searchParams.get('dfc-env')
  if (searchParams.get('dfc-env')) {
    localStorage.removeItem('dfc-disable');
  }

  // iife flags for closure
  const [env, disable] = (() => {
    return ['env', 'disable'].map(key => {
      const ns = `dfc-${key}`;
      const value = searchParams.get(ns) || getWithExpiry(ns);
      if (value) {
        setWithExpiry(ns, value);
      }
      dfc[key] = value;
      return value;
    });
  })();

  // then we disable and adjust based on config
  if (disable) {
    throw new Error('dfc: disabled');
  }

  // add do not track flag for any env
  if (env) doNotTrack();
  if (!dfc.onExp) {
    dfc.onExp = fn => {
      dfc.experiments.forEach(arr => fn(arr[0], arr[1]));
      dfc.trackers.push(fn);
    };
  }
  const loadScript = (url, callback, onerror) => {
    // log('loadScript', url);
    const script = document.createElement('script');
    const scripts = document.getElementsByTagName('script')[0];
    script.src = url;
    if (callback) script.onload = () => callback(script);
    // eslint-disable-next-line no-console
    script.onerror = onerror || (e => console.error('dfc.loadScript', url, e));
    scripts.parentNode.insertBefore(script, scripts);
  };
  dfc.loadScript = src => new Promise(loadScript.bind(null, src));
  dfc.loadLink = url => {
    // log('loadLink', url);
    const head = document.getElementsByTagName('HEAD')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    head.appendChild(link);
  };
  if (env === 'dev') {
    dfc.host = `https://localhost:8888`;
  } else if (!env || env === 'main') {
    // dfc.host = `https://digitalfuelcapital.pages.dev`;
    dfc.host = `https://digitalfueloptimize.com`;
  } else {
    // shouldn't be possible to get here but just in case
    if (!env) throw new Error('DFC ENV required for non-main env');
    dfc.host = `https://${env}.digitalfuelcapital.pages.dev`;
  }

  /****************************************************************************
   * Pivot off environment flag for loading preview links
   *****************************************************************************/

  if (/^(staging|dev|(pr|release)-\d+)$/.test(env) && !window._dfcEnvLoaded) {
    window._dfcEnvLoaded = {
      env
    };
    const client = (() => {
      const script = document.querySelector('script[src*="digitalfueloptimize.com/"], script[src*="digitalfuelcapital.pages.dev/"], script[src*="dgjvwr5wei7tc.amplifyapp.com/"]');
      const src = (script && script.getAttribute('src') || '//./').split('//')[1];
      const client = src.split('/')[1];
      return client;
    })();

    // if we're not on the desired env then load it
    loadScript(`${dfc.host}/${client}/index.js`);
    throw new Error(`dfc-env set - stopping execution to load ${env}`);
  }

  // prevent double framework load from timex
  if (dfc.loaded) {
    throw new Error(`window.dfc.loaded===true - don't load twice`);
  }
  dfc.loaded = true;

  // Logging
  const noop = () => {};
  dfc.log = noop;
  dfc.error = noop;
  if (env) {
    // eslint-disable-next-line no-console
    dfc.log = console.log.bind(console, 'dfc');
    // eslint-disable-next-line no-console
    dfc.error = console.error.bind(console, 'dfc');
    dfc.log('running v2.js version', JSON.stringify({
      env: env,
      inline: dfc.version
    }));
    if (env === 'dev') {
      loadScript(`https://localhost:35729/livereload.js?snipver=1`);
    }
    loadScript(`${dfc.host}/notify.js`);
  }
  async function wrap(str, fn, ...args) {
    try {
      return await fn(...args);
    } catch (e) {
      if (e) {
        dfc.error(str, e);
      }
    }
  }
  dfc.wrap = wrap;
  async function safe(fn, ...args) {
    try {
      return await fn(...args);
    } catch (e) {
      if (env) {
        // eslint-disable-next-line no-console
        console.error('DFC ERROR', e);
      }
    }
  }
  dfc.safe = safe;
  dfc.getCookie = function (cname) {
    const nameEQ = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  // domReady
  dfc.ready = new Promise(res => {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      res();
    } else {
      document.addEventListener('DOMContentLoaded', res);
    }
  });

  // window load
  dfc.load = new Promise(res => {
    dfc.log('document.readyState', document.readyState);
    if (document.readyState === 'complete') {
      res();
    } else {
      window.addEventListener('load', res);
    }
  });
  let stop = false;
  dfc.load.then(() => {
    dfc.log('page loaded');
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => {
      stop = true;
    }, 250);
  });

  /**
   * Add a style tag to the head
   * @param {*} css
   * @returns {HTMLStyleElement}
   * @example
   * dfc.addStyle(`.el-class { color: red; }`);
   * @example with id for removal later
   * const styleId = dfc.addStyle('body {display:none}');
   * await dfc.load
   * dfc.qs(`#${styleId}`).remove();
   * @example remove after load event
   * dfc.load.then(() => {
   * const $style = dfc.qs(`#${styleId}`);
   * $style && $style.remove();
   * });
   */
  let _idx = 0;
  dfc.addStyle = css => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.id = `dfc-style-${_idx++}`;
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    return style.id;
  };
  const jq = (callback, onError) => {
    const $ = window.jQuery;
    if ($ && $.fn && $.fn.jquery) safe(callback.bind(null, $));else if (stop) onError(new Error('dfc.jQuery jQuery not found'));else window.requestAnimationFrame(jq.bind(null, callback, onError));
  };

  // weird cache so we don't poll all the time
  let jQuery = null;
  Object.defineProperty(dfc, 'jQuery', {
    get: function () {
      return jQuery || (jQuery = new Promise(jq.bind(null)));
    }
  });
  const raf = (selector, callback, onError) => {
    const $els = document.querySelectorAll(selector);
    const found = !!$els.length;
    if (found) return safe(callback.bind(null, $els));
    if (stop) onError && onError();else window.requestAnimationFrame(raf.bind(null, selector, callback, onError));
  };

  /**
   * @param {*} selector
   * @returns {Promise<HTMLElement[]>}
   * @example
   * const els = await dfc.raf('.el-class');
   * els.forEach(el => el.style.color = 'red');
   */
  dfc.raf = selector => new Promise(raf.bind(null, selector));

  /**
   * @param {*} selector
   * @returns {Promise<HTMLElement>}
   * @example
   * const el = await dfc.find('#my-id');
   * el.style.color = 'red';
   */
  dfc.find = selector => dfc.raf(selector).then($els => $els[0]);
  dfc.$raf = selector => new Promise(jq.bind(null)).then(async $ => {
    const $els = await dfc.raf(selector);
    return $($els);
  });
  dfc.qs = document.querySelector.bind(document);
  dfc.qsa = (selector, context = document) => Array.from(context.querySelectorAll(selector));
  dfc.createEl = str => {
    const div = document.createElement('div');
    div.innerHTML = str.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  };
  dfc.live = (selector, event, callback, context) => {
    // helper for enabling IE 8 event bindings
    function addEvent(el, type, handler) {
      if (el.attachEvent) el.attachEvent('on' + type, handler);else el.addEventListener(type, handler);
    }
    // live binding helper using matchesSelector
    function live(selector, event, callback, context) {
      addEvent(context || document, event, e => {
        let found,
          el = e.target || e.srcElement;
        while (el && el.matches && el !== context && !(found = el.matches(selector))) el = el.parentElement;
        if (found) callback.call(el, e);
      });
    }
    live(selector, event, callback, context);
  };

  // eslint-disable-next-line no-restricted-globals
  dfc.sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  dfc.waitFor = async f => {
    let resp;
    while (!(resp = f()) && !stop) await new Promise(requestAnimationFrame);
    if (resp) return resp;else throw new Error('dfc.waitFor: f() not true by stop', f);
  };
  dfc.waitForever = async f => {
    let resp;
    while (!(resp = f())) await new Promise(requestAnimationFrame);
    return resp;
  };
  dfc.waitForSeconds = async (f, seconds = 5) => {
    let resp,
      stop = false;
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() => {
      stop = true;
    }, seconds * 1000);
    while (!(resp = f()) && !stop) await new Promise(requestAnimationFrame);
    return resp;
  };

  // dfc.removeElementById = (id) => {
  //   const elem = document.getElementById(id);
  //   return elem && elem.parentNode.removeChild(elem);
  // };

  // dfc.setParam = (str, k, v) => {
  //   const url = (() => {
  //     try {
  //       return new URL(str);
  //     } catch (e) {
  //       return new URL(str, location.href);
  //     }
  //   })();
  //   url.searchParams.set(k, v);
  //   return url.toString();
  // };

  // dfc.$setParam = ($a, k, v) => {
  //   const href = $a.attr('href');
  //   return $a.attr('href', dfc.setParam(href, k, v));
  // };

  // cspell:ignore cvalue, exdays
  // function setCookie(cname, cvalue, exdays) {
  //   const d = new Date();
  //   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  //   const expires = 'expires=' + d.toUTCString();
  //   document.cookie = cname + '=' + cvalue + ';' + expires + ';path`=/';
  // }

  /**
   * Hoisted Helper Functions
   */

  async function run(row, fn, ...args) {
    // weird hack to paste run in console when in dev
    if (typeof row === 'function') {
      // eslint-disable-next-line no-console
      console.error('The DFC files should only be utilized after they have undergone compilation. This code ' + 'is intended to serve as a starting point, it should not be implemented as is and ' + 'should be refactored before being integrated into production.');
      return env && dfc.wrap('run', row, ...[fn, ...args]);
    }
    const {
      experiment,
      expId,
      status
    } = row;
    const key = `dfc-exp-${expId}`;
    dfc.friendly[expId] = row;
    // set 1 for 100%s
    if (status === '100%' && parseInt(localStorage.getItem(key), 10) !== 1) {
      localStorage.setItem(key, 1);
    }

    // run everything on non main envs
    if (env && env !== 'main') {
      return dfc.wrap(experiment, fn, ...args);
    } else {
      // on deck run only when env flag set:
      if (status === 'ONDECK' && env === 'main') return dfc.wrap(experiment, fn, ...args);
      // on main, run live and 100%s
      if (status === 'LIVE' || status === '100%') return dfc.wrap(experiment, fn, ...args);
    }
  }
  dfc.run = run;
  dfc.friendly = {};
  dfc.prebucket = (id, variations = 2) => {
    let idx = window.dfc(`prebucket-${id}`, variations);
    if (/^100-/.test(id)) idx = 1;
    localStorage.setItem(`dfc-exp-${id}`, idx);
    return {
      idx,
      activate: () => window.dfc(id, variations)
    };
  };

  /**
   * Optimize and Experience Linking
   */

  dfc.shouldIgnoreRegex = /^(100|preview|prebucket|sku|ignore|todo|ondeck)/i;
  dfc.shouldIgnore = id => {
    if (!id) {
      dfc.log('dfc.shouldIgnore: ID IS NULL', id);
      return true;
    }
    if (dfc.DO_NOT_TRACK) {
      dfc.log('dfc.shouldIgnore: DO_NOT_TRACK', id);
      return true;
    }
    if (dfc.shouldIgnoreRegex.test(id)) {
      dfc.log('dfc.shouldIgnore: ignore', id);
      return true;
    }
    return false;
  };
  dfc.trackExperiences = async ({
    dedupeMs = 1000 * 60 * 60 * 12,
    onReady = false
  } = {}) => {
    // https://developers.google.com/analytics/devguides/collection/ga4/integration
    await dfc[onReady ? 'ready' : 'load'];
    dfc.onExp((id, idx) => {
      if (dfc.shouldIgnore(id)) return;
      if (dedupeMs > 1) {
        // hack to confirm valid number
        const ns = `dfc-ga4exp-${id}`;
        const value = dfc.getWithExpiry(ns);
        if (parseInt(value, 10) === parseInt(idx, 10)) return;
        dfc.setWithExpiry(ns, idx, dedupeMs);
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('event', 'experience_impression', {
        // Replace the value with the Experiment-variant ID
        exp_variant_string: `DFC-${id}-${idx}`
      });
    });
  };
  dfc.trackExperimentsLocally = async ({
    dedupeMs = 1000 * 60 * 60 * 24,
    onReady = false
  } = {}) => {
    // Wait for the document to be ready or fully loaded
    await dfc[onReady ? 'ready' : 'load'];
    const localStorageKey = 'dfc-experiment-interactions';
    const timestamp = Date.now();

    // Ensure the local storage array exists
    const getExperimentData = () => {
      const data = localStorage.getItem(localStorageKey);
      return data ? JSON.parse(data) : [];
    };
    const saveExperimentData = data => {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    };

    // Clean up old experiments
    const cleanOldExperiments = () => {
      const experiments = getExperimentData();
      const filteredExperiments = experiments.filter(exp => timestamp - exp.timestamp <= 1000 * 60 * 60 * 24); // 24 hours
      saveExperimentData(filteredExperiments);
    };

    // Initial cleanup to ensure no stale data
    cleanOldExperiments();
    dfc.onExp((id, idx) => {
      if (dedupeMs > 1) {
        const ns = `dfc-local-exp-${id}`;
        const value = dfc.getWithExpiry(ns);
        if (parseInt(value, 10) === parseInt(idx, 10)) return;
        dfc.setWithExpiry(ns, idx, dedupeMs);
      }

      // Add the experiment interaction to local storage
      const experiments = getExperimentData();
      experiments.push({
        id,
        idx,
        timestamp // Useful for tracking when the interaction occurred
      });

      // Clean up old experiments and save updated data back to local storage
      cleanOldExperiments();
      saveExperimentData(experiments);
    });
  };
  dfc.getExperimentString = () => {
    const localStorageKey = 'dfc-experiment-interactions';

    // Retrieve the experiment data from localStorage
    const experiments = JSON.parse(localStorage.getItem(localStorageKey)) || [];

    // Map the data to `id-idx` format and join them with commas
    const experimentString = experiments.map(exp => `${exp.id}-${exp.idx}`).join(',');
    return experimentString;
  };
  dfc.trackClarity = async () => {
    await dfc.load;
    if (!window.clarity) return;
    /*global clarity*/

    const activated = [];
    dfc.onExp((id, idx) => {
      if (dfc.shouldIgnore(id)) return;
      activated.push([id, idx]);
    });
    if (activated.length) {
      // prettier-ignore
      clarity('set', 'experiment', activated.map(([id, idx]) => `${id}:v${idx}`));
    }
    activated.push = ([id, idx]) => {
      clarity('set', 'experiment', `${id}:v${idx}`);
    };
  };

  // call like this: dfc.track('exp1-right-click');
  dfc.track = async _name => {
    dfc.log('dfc.track: ', _name);
    if (dfc.DO_NOT_TRACK) return dfc.log('dfc.DO_NOT_TRACK');
    await dfc.load; // wait till load

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('event', 'dfc_track_' + _name.toLowerCase().replace(/[-\s]+/g, '_').replace(/[^a-z0-9_]/g, '').replace(/__+/g, '_').replace(/^_|_$/g, ''));
  };
  dfc.setWithExpiry = setWithExpiry;
  function setWithExpiry(key, value, ms = 1000 * 60 * 30) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ms
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  dfc.getWithExpiry = getWithExpiry;
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
})();
dfc.trackClarity();
dfc.trackExperiences();
window.scgAnalytics = async (action, label) => {
  dfc.track(`scg-${action}-${label}`);
};
dfc.loadScript(dfc.host + '/statsig.scg.js').then(async () => {
  /*global statsigMicrolib*/

  // track back on load
  await dfc.load;
  dfc.onExp((id, idx) => {
    if (dfc.shouldIgnore(id)) return;
    statsigMicrolib.onBucket(id, idx);
  });
});
"use strict";

// NOT A TEST

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-000-a-plp-hide-promo-card.js",
  "expId": "ondeck-CRO-000-a-plp-hide-promo-card",
  "jiraCard": null
}, async () => {
  // If on the following pages: /collections/breville or /collections/baratza or /collections/lelit

  const regex = /\/collections\/(jura|acaia|ascaso|baratza|BUNN|Curtis|Eureka|La-Marzocco|Lelit|Mahlkonig|Mazzer|Technivorm-Moccamaster|open-box-merchandise|espresso-machine-bundles|fellow)\/?$/;
  if (!regex.test(location.pathname)) return;

  // Add style to hide the swatch
  dfc.addStyle(` #plp-promo { display: none !important; } `);
  const idx = window.dfc('ondeck-CRO-000-a-plp-hide-promo-card');
  if (idx === 0) return;
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1710
// CRO-1710 - A - PDP - Increase Header Size
// Portco: seattlecoffeegear.com

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1710-a-pdp-increase-header-size.js",
  "expId": "ondeck-CRO-1710-a-pdp-increase-header-size",
  "jiraCard": "CRO-1710"
}, async () => {
  // Run only on PDP
  if (!location.pathname.includes('/products/')) return;

  // Create the index for test
  const idx = window.dfc('ondeck-CRO-1710-a-pdp-increase-header-size');

  // If 0, return
  if (idx == 0) return;

  // Add Style to Element
  // Desktop - Moved from 36px --> 40px. Line height from 40px to 32px
  // Mobile - 30px --> 34px. Line height from 36px to 30px
  dfc.addStyle(` @media (min-width: 1024px) { .type__heading.type__heading--product-title { font-size: 40px !important; line-height: 32px !important; } } @media (max-width: 1024px) { .type__heading.type__heading--product-title { font-size: 36px !important; line-height: 30px !important; } } `);
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1763
// CRO - 1763 - A - PDP - Whats Included Style
// Portco: seattlecoffeegear.com

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1763-a-pdp-whats-included-style.js",
  "expId": "ondeck-CRO-1763-a-pdp-whats-included-style",
  "jiraCard": "CRO-1763"
}, async () => {
  // Quit if not on PDP
  if (!location.pathname.includes('/products/')) return;

  // Find desktop and mobile 'whats included' elements; Quit test if not found
  const whatsIncludedArr = await dfc.raf('#product__box-included p');
  if (whatsIncludedArr.length == 0) return;

  // Destructure array to target individual elements
  const [desktopWhatsIncluded, mobileWhatsIncluded] = whatsIncludedArr;

  // Create the index for test
  const idx = window.dfc('ondeck-CRO-1763-a-pdp-whats-included-style');

  // If 0, do nothing
  if (idx == 0) return;

  // Add styling to new list elements
  dfc.addStyle(` #product__box-included ul { display: flex !important; } #product__box-included li { background-color: rgb(249 250 251); display: inline-flex; max-width: fit-content; list-style-type: none !important; border: 1px solid rgb(71 85 105); border-radius: 1.5rem; margin-right: 10px; margin-bottom: 10px; padding: 3px 15px; } @media (max-width: 768px) { #product__box-included li { font-size: .8rem; padding: 1px 10px; } } `);

  // Function to split included items from string to array
  function splitStringToArray(str) {
    return str.split('').reduce((acc, char) => {
      if (char === ',') {
        acc.push('');
      } else {
        acc[acc.length - 1] += char;
      }
      return acc;
    }, ['']);
  }

  // Create an array of included items out of the text within 'whats included' section for both mobile and desktop elements
  const desktopIncludedItemsArr = splitStringToArray(desktopWhatsIncluded.innerHTML);
  const mobileIncludedItemsArr = splitStringToArray(mobileWhatsIncluded.innerHTML);

  // Create a new desktop list
  const $desktopReplacementSection = dfc.createEl(` <div id="product__box-included" class="mt-1"> <ul id="desktop-included-list"> </ul> </div> `);
  // Create a new mobile list
  const $mobileReplacementSection = dfc.createEl(` <div id="product__box-included" class="mt-1"> <ul id="mobile-included-list"> </ul> </div> `);

  // Replace old elements with new list elements
  desktopWhatsIncluded.parentNode.parentNode.replaceChild($desktopReplacementSection, desktopWhatsIncluded.parentNode);
  mobileWhatsIncluded.parentNode.parentNode.replaceChild($mobileReplacementSection, mobileWhatsIncluded.parentNode);

  // Loop through desktop included items array and create x amount of new list elements
  desktopIncludedItemsArr.forEach(element => {
    const includedItem = dfc.createEl(` <li></li> `);
    // Update text content of element
    includedItem.innerHTML = '✓ ' + element;

    // Append includedItem to replacementSection
    $desktopReplacementSection.appendChild(includedItem);
  });

  // Loop through mobile included items array and create x amount of new list elements
  mobileIncludedItemsArr.forEach(element => {
    const includedItem = dfc.createEl(` <li></li> `);
    // Update text content of element
    includedItem.innerHTML = '✓ ' + element;

    // Append includedItem to replacementSection
    $mobileReplacementSection.appendChild(includedItem);
  });
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1878
// CRO-1878 - A - Nav - Branding
// Portco: seattlecoffeegear.com

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1878-a-nav-branding.js",
  "expId": "ondeck-CRO-1878-a-nav-branding",
  "jiraCard": "CRO-1878"
}, async () => {
  // Run on Desktop
  if (!window.innerWidth > 768) return;
  const {
    idx
  } = window.dfc('ondeck-CRO-1878-a-nav-branding');
  if (idx === 0) return;
  const $desktopNavLinks = document.querySelectorAll('a.header__link');
  $desktopNavLinks[0].childNodes[0].textContent = 'Browse Machines';
  $desktopNavLinks[1].childNodes[0].textContent = 'Discover Coffee';
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1904
// CRO-1904 - D - All - Blinking Tab
// Portco: seattlecoffeegear.com

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "100%",
  "experiment": "CRO-1904-d-all-blinking-tab.js",
  "expId": "100-CRO-1904-d-all-blinking-tab",
  "jiraCard": "CRO-1904"
}, async () => {
  // Only run on desktop
  if (window.innerWidth < 990) return;
  const {
    idx,
    activate
  } = dfc.prebucket('100-CRO-1904-d-all-blinking-tab');
  let isTestActive = false;
  const originalTitle = document.title;
  let blinkInterval;
  let blinkTimeout;
  document.onvisibilitychange = () => {
    // Fire a GA event when a visitor abandons/returns to the tab
    dfc.track(`CRO-1904-tab-${document.visibilityState}`);
    if (document.visibilityState === 'hidden') {
      // Activate the test once the user leaves the tab
      if (!isTestActive) {
        activate();
        isTestActive = true;
      }
      // Wait 8 seconds before starting the blinking
      blinkTimeout = window.setTimeout(() => {
        // Fire a GA event after 8 seconds of inactivity
        dfc.track('CRO-1904-tab-blink-started');
        // if variant, start the blinking
        if (idx == 1) {
          blinkInterval = window.setInterval(() => {
            document.title = document.title === originalTitle ? 'We miss you! ☕' : originalTitle;
          }, 3000); // Blink every 3 seconds
        }
      }, 8000); // 8 second delay
    } else {
      // User has returned to the tab
      // Cancel the blinking start if it was waiting
      clearTimeout(blinkTimeout);
      // Stop blinking if it started
      clearInterval(blinkInterval);
      // Reset the title
      document.title = originalTitle;
    }
  };
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1982
// CRO-1982 - A - PLP - Quick Nav Show/Hide
// Portco: seattlecoffeegear.com

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1982-a-plp-quick-nav-show-hide.js",
  "expId": "ondeck-CRO-1982-a-plp-quick-nav-show-hide",
  "jiraCard": "CRO-1982"
}, async () => {
  // Only run on PLP
  if (!location.pathname.includes('/collections/')) return;
  const styleId = dfc.addStyle(` #plp-quick-nav { display: none !important; } `);
  await dfc.find('#plp-quick-nav');
  const idx = window.dfc('ondeck-CRO-1982-a-plp-quick-nav-show-hide');
  if (idx === 0) return;
  document.getElementById(styleId).remove();
});

// https://digitalfueltesting.atlassian.net/browse/CRO-1994
// CRO-1994 - M - PDP - Hide Cross Sells
// Portco: seattlecoffeegear.com

dfc.run({
  "client": "seattlecoffeegear.shopify.com",
  "status": "ONDECK",
  "experiment": "CRO-1994-m-pdp-hide-cross-sells.js",
  "expId": "ondeck-CRO-1994-m-pdp-hide-cross-sells",
  "jiraCard": "CRO-1994"
}, async () => {
  // Only run on Mobile
  if (window.innerWidth > 768) return;

  // Only run on PDP
  if (!window.location.pathname.includes('/products/')) return;

  // Find the 'Complete Your Setup' sections
  const $completeYourSetupSection = await dfc.raf('#product-upsell');

  // If no 'Complete Your Setup' section, return
  if (!$completeYourSetupSection) return;

  // Create the index
  const idx = window.dfc('ondeck-CRO-1994-m-pdp-hide-cross-sells');

  // If 0, show cross sells
  if (idx === 0) return;

  // If 1, hide cross sells
  dfc.addStyle(` #product-upsell { display: none; } `);
});